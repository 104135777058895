import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/custom/button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import useUser from '@/api/hooks/useUser'
import { textToAvatarImg, toggleFullscreenV2 } from '@/utils'
import { clickOnFullScreenOption, clickOnLogoutOption, clickOnProfileAvatar, clickOnViewProfileOption } from '@/lib/events'
import { AlertDialogDemo } from '@/components/modals/LogoutModal'
import { useState } from 'react'

export function UserNav() {
    const navigation = useNavigate()
    const { user } = useUser()
    const [isOpen, setIsOpen] = useState(false)
    const [isFullScreen, setIsFullScreen] = useState(false)

    const handleLogout = (e) => {
        clickOnLogoutOption()
        // navigation('/logout')
        setIsOpen(true)
        console.log(e)
    }

    const handleTriggerClick = () => {
        console.log('Click')
        clickOnProfileAvatar()
    }

    const handleProfileClick = () => {
        clickOnViewProfileOption()
        navigation('/dashboard/profile')
    }

    const handleDebugClick = () => {
        navigation('/dashboard/debug')
    }

    const handleFullScreen = () => {
        clickOnFullScreenOption({ isFullScreen: !isFullScreen })
        setIsFullScreen((prev) => !prev)
        toggleFullscreenV2()
    }

    return (
        <>
            <DropdownMenu>
                <div className='flex items-center  gap-2'>
                    <p className='hidden text-sm leading-none text-muted-foreground sm:block' onClick={handleTriggerClick}>
                        {user?.email || '-'}
                    </p>
                    <DropdownMenuTrigger asChild>
                        <div className='flex items-center '>
                            <Button variant='outline' className=' relative h-8 w-8 rounded-full'>
                                <>
                                    <Avatar className='h-8 w-8 '>
                                        <AvatarImage src='/avatars/01.png' alt='@shadcn' />
                                        <AvatarFallback>{textToAvatarImg(user?.email)}</AvatarFallback>
                                    </Avatar>
                                </>
                            </Button>
                            <Icon fontSize={24} icon={'solar:alt-arrow-down-linear'} className='ml-2' />
                        </div>
                    </DropdownMenuTrigger>
                </div>

                <DropdownMenuContent className='w-56' align='end' forceMount>
                    {/* <DropdownMenuLabel className='font-normal'>
          <div className='flex flex-col space-y-1'>
            <p className='text-sm font-medium leading-none'>{user?.name}</p>
            <p className='text-xs leading-none text-muted-foreground'>
              {user?.email}
            </p>
          </div>
        </DropdownMenuLabel> */}
                    {/* <DropdownMenuSeparator /> */}
                    <DropdownMenuGroup>
                        <DropdownMenuItem onClick={handleProfileClick}>
                            <Icon icon={'solar:user-line-duotone'} className='mr-2 text-base font-semibold' />
                            View Profile
                        </DropdownMenuItem>
                        {/* {ENVIRONMENT !== 'production' &&   <DropdownMenuItem onClick={handleDebugClick}>
            <Icon
              icon={'solar:test-tube-minimalistic-bold-duotone'}
              className='mr-2 text-base font-semibold'
            />
            Debug
          </DropdownMenuItem>} */}
                        <DropdownMenuItem onClick={handleFullScreen}>
                            <Icon icon={isFullScreen ? 'solar:minimize-square-2-line-duotone' : 'solar:full-screen-square-line-duotone'} className='mr-2 text-base font-semibold' />
                            {isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
                        </DropdownMenuItem>
                        {/*  <DropdownMenuItem>
              <Icon
                icon={'solar:settings-line-duotone'}
                className='mr-2 text-base font-semibold'
              />
              Settings
            </DropdownMenuItem> */}
                    </DropdownMenuGroup>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem onClick={handleLogout}>
                        <Icon icon={'solar:logout-2-line-duotone'} className='mr-2 text-base font-semibold' />
                        Log out
                        {/* <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut> */}
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
            <AlertDialogDemo isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
    )
}
