import axios from 'axios'

const JSONParse = (value: string) => {
  try {
    return JSON.parse(value)
  } catch (error) {
    return value
  }
}

export const axiosWrapper = (() => {
  let jwdToken
  try {
    jwdToken = localStorage.getItem('token')
  } catch (err) {
    jwdToken = ''
  }
  jwdToken = jwdToken
    ? JSONParse(jwdToken)
    : ''
  const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwdToken}`,
    },
  })
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        // Handle the 401 exception
        setTimeout(() => {
          // window.location.href = '/login'
          // localStorage.setItem('token', '')
        }, 2000)
        console.log('Unauthorized request')
      }
      return Promise.reject(error)
    }
  )
  return axiosInstance
})()
