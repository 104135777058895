import { createAsyncThunk } from '@reduxjs/toolkit'
// import { ISendLoginOtp } from '@/utils/types'
import { NETWORK_STORE_KEY } from '@/store/redux/key'
import { fetchDataClientsInfo, fetchErUserFromId, refetchAuthToken } from '@/api/services/user.service'
import { redEvent } from '@/lib/red-hooks'
import { IErUser } from '@/utils/types'
import { updateSelectedSource } from '@/store/redux/orders/orderSlice'
import { updateClientList } from '@/store/redux/user/userSlice'

const messageToken = localStorage.getItem('messageToken')

export const verifyAuthToken = createAsyncThunk(NETWORK_STORE_KEY['@auth/verify-auth-token'], async (token: string, { dispatch }) => {
    const data = await refetchAuthToken(token)

    if (data?.data) {
        const user: any = data?.data
        const dta: any = await fetchErUserFromId(user.id)
        const clientInfo = await fetchDataClientsInfo()
        const clientInfoMapper = clientInfo?.data?.reduce((acc: any, itm: any) => {
            acc[itm.clientId] = itm
            return acc
        }, {})

        const userInfo: IErUser = dta?.data.data


        const payload = {
            Name: userInfo?.fullName,
            Identity: userInfo?.userId,
            Email: userInfo?.email,
            Phone: userInfo?.primaryPhoneNo ? `+91${userInfo?.primaryPhoneNo}` : undefined,
            push_token: messageToken,
        }

        redEvent.identify(
            {
                userId: userInfo.userId,
                userDetails: payload,
            },
            ['clevertap', 'segment']
        )
        if (Array?.isArray(userInfo?.dedicatedToClientIds)) {
            userInfo.dedicatedToClientIds = userInfo.dedicatedToClientIds.map((itm: string) => ({
                label: clientInfoMapper[itm]?.clientName,
                value: itm,
            }))
        }

        dispatch(updateSelectedSource(userInfo?.clientId))
        dispatch(updateClientList({ clientList: clientInfo?.data, selectedClientId: userInfo?.clientId, clientMap: clientInfoMapper }))

        return userInfo
    }
    return data?.data
})

function labelValueGenerator(label: string, value: any) {
    return {
        label,
        value,
    }
}

// export const getUserClientInfo = createAsyncThunk(
//   NETWORK_STORE_KEY['@clients/get-user-client-info'],
//   async () => {
//     const data = await fetchDataClientsInfo()
//     return data?.data
//   }
// )

// export const getErUserThunk = createAsyncThunk(
//   NETWORK_STORE_KEY['@er-user/fetch-er-user'],
//   async (id: string) => {
//     const data = await fetchErUserFromId(id)
//     return data?.data
//   }
// )
