import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fiveGVehicles, orderInitialState } from '@/store/redux/orders/orderStore.constant'
import {
  fetchCaseHistoryThunk,
  fetchOngoingOrdersThunk,
} from '@/api/thunks/order.thunk'

const ordersSlice = createSlice({
  name: 'orders',
  initialState: orderInitialState,
  reducers: {
    updateSelectedOrderId: (state, action: PayloadAction<string>) => {
      state.selectedOrderId = action.payload
    },
    updateSelectedSource: (state, action: PayloadAction<string>) => {
      state.caseHistoryFilter.selectedSource = action.payload
    },
    updateSearchKeyword: (state, action: PayloadAction<string>) => {
      state.caseHistoryFilter.searchKeyword = action.payload
    },
    updateSelectedDateRange: (state, action: PayloadAction<any>) => {
      state.caseHistoryFilter.dateRange = action.payload
    },
    updateTripTrackingData: (state, action: PayloadAction<any>) => {
      const {orderId, trackingObj} = action.payload;
      const currentCaseIndex = state.ongoingCases.findIndex((entry)=> entry.caseId === orderId);
      if(currentCaseIndex !== -1) {
        state.ongoingCases[currentCaseIndex] = {
          ...state.ongoingCases[currentCaseIndex],
          tripTracking: trackingObj
        }
      }
    },
    moveCaseToFulfilled: (state, action) => {
      const {orderId} = action.payload;
      const currentCase = state.ongoingCases.filter((entry)=> entry.caseId === orderId);
      const updatedOngoingCases = state.ongoingCases.filter((entry: any) => {
        return entry?.caseId !== orderId;
      })
      state.ongoingCases = updatedOngoingCases;
      state.fulfilledCases = [...state.fulfilledCases, currentCase?.[0]];
      state.selectedOrderId = null;
      state.refetchSummary = true;
    },
    updateEmergencyCaseState: (state, action) => {
      const orderData = action.payload;
      const caseId = orderData?.caseId;
      if(orderData?.orderClassification !== 'INBOUND') {
        const updatedOrders = state.ongoingCases.filter((entry: any)=> entry?.caseId !== caseId);
        state.ongoingCases = updatedOrders;
      }  
      else {
        const currentCaseIndex = state.ongoingCases.findIndex((entry)=> entry.caseId === caseId);
        state.ongoingCases[currentCaseIndex] = {
          ...orderData,
          overallStatus: state.ongoingCases[currentCaseIndex]?.overallStatus,
          tripTracking: state.ongoingCases[currentCaseIndex]?.tripTracking,
        };
      }
    },
    updateOrderStatus: (state, action: PayloadAction<any>) => {
      const {orderId, status} = action.payload;
      const currentCaseIndex = state.ongoingCases.findIndex((entry)=> entry.caseId === orderId);
      if(currentCaseIndex !== -1) {
        state.ongoingCases[currentCaseIndex] = {
          ...state.ongoingCases[currentCaseIndex],
          overallStatus: status,
          tripTracking: null
        }
      state.refetchSummary = true;
      }
    },
    AddNewOngoingCase: (state, action: PayloadAction<any>) => {
      state.ongoingCases = [{...action.payload, overallStatus: 'dispatched', type: fiveGVehicles.includes(action.payload?.assignmentDetails?.fleetDetails?.ambNumberPlate) ? '5g': 'non_5g'} ,...state.ongoingCases]
      state.refetchSummary = true;
    },
    updateRefetchSummaryFlag: (state, action: PayloadAction<any>) => {
      state.refetchSummary = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOngoingOrdersThunk.pending, (state) => {
        state.status.fetchOngoingOrders = 'loading'
        state.errors.fetchOngoingOrders = null
      })
      .addCase(
        fetchOngoingOrdersThunk.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.status.fetchOngoingOrders = 'succeeded'
          state.status.fetchFulfilledOrders = 'succeeded'
          state.fulfilledCases = action.payload.completed
          state.ongoingCases = action.payload.ongoing

        }
      )
      .addCase(fetchOngoingOrdersThunk.rejected, (state, action) => {
        state.status.fetchOngoingOrders = 'failed'
        state.status.fetchOngoingOrders =
          action.error.message || 'Failed to fetch orders'
      })
      .addCase(fetchCaseHistoryThunk.pending, (state) => {
        state.status.fetchCaseHistory = 'loading'
        state.errors.fetchCaseHistory = null
      })
      .addCase(
        fetchCaseHistoryThunk.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.status.fetchCaseHistory = 'succeeded'
          state.caseHistory = action.payload
        }
      )
      .addCase(fetchCaseHistoryThunk.rejected, (state, action) => {
        state.status.fetchCaseHistory = 'failed'
        state.status.fetchCaseHistory =
          action.error.message || 'Failed to fetch orders'
      })
  },
})

export default ordersSlice.reducer
export const {
  updateSelectedOrderId,
  updateSelectedSource,
  updateSelectedDateRange,
  AddNewOngoingCase,
  updateSearchKeyword,
  updateTripTrackingData,
  updateOrderStatus,
  updateRefetchSummaryFlag,
  moveCaseToFulfilled,
  updateEmergencyCaseState
} = ordersSlice.actions
