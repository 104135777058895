import { AnyObject } from '@/utils/types'

export const toggleFullscreen = (elementId: string) => {
  const fullscreenDiv = document.getElementById(elementId)

  if (fullscreenDiv) {
    if (!document.fullscreenElement) {
      fullscreenDiv
        .requestFullscreen()
        .then(() => {
          console.log('Entered fullscreen mode.')
        })
        .catch((err) => {
          console.error('Error attempting to enable fullscreen:', err)
        })
    } else {
      document.exitFullscreen().then(() => {
        console.log('Exited fullscreen mode.')
      })
    }
  } else {
    console.error(fullscreenDiv, ' not found.')
  }
}

export const cleanObject = (obj: AnyObject): AnyObject => {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key]
    if (value !== null && value !== undefined) {
      acc[key] = value
    }
    return acc
  }, {} as AnyObject)
}

export const createQueryString = (obj: AnyObject): string => {
  const cleanedObj = cleanObject(obj)
  const params = new URLSearchParams(cleanedObj)
  return params.toString()
}

export function debounce<T extends (...args: any[]) => void>(
  func: T,
  wait: number
): T {
  let timeout: ReturnType<typeof setTimeout>
  return function executedFunction(...args: Parameters<T>) {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  } as T
}

export function textToAvatarImg(text: string) {
  if (text && text?.length >= 2) {
    return `${text[0]}${text[1]}`?.toUpperCase()
  } else if (text && text?.length === 1) {
    return `${text[0]}`?.toUpperCase()
  } else {
    return 'NA'?.toUpperCase()
  }
}

type FlattenedObject = Record<string, any>

export function flattenObject(
  obj: Record<string, any>,
  parentKey: string = '',
  separator: string = '.',
  depth: number = 0,
  maxDepth: number = 5
): FlattenedObject {
  if (depth > maxDepth) {
    return { [parentKey]: obj }
  }

  return Object.keys(obj).reduce((acc: FlattenedObject, key: string) => {
    const fullKey = parentKey ? `${parentKey}${separator}${key}` : key
    const value = obj[key]

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      Object.assign(
        acc,
        flattenObject(value, fullKey, separator, depth + 1, maxDepth)
      )
    } else {
      acc[fullKey] = value
    }

    return acc
  }, {} as FlattenedObject)
}

export const addressGeneratorFromWaypoint = (data: any, addFloor = true) => {
  return [
    data?.address?.floor && addFloor ? `Floor - ${data?.address?.floor}` : '',
    data?.address?.location,
    data?.address?.city,
    data?.address?.state,
    data?.address?.pincode,
  ]
    .filter(Boolean)
    .join(', ')
}

export function muskContinuesValue(
  value = '',
  till: number,
  pixelSize?: number,
  direction?: 'prefix' | 'suffix'
) {
  const pixel = pixelSize || 4.5
  const percentage = parseInt(
    ((window.innerWidth * till) / (100 * pixel)).toFixed(2)
  )

  if (
    value &&
    value.length > percentage &&
    direction &&
    direction === 'prefix'
  ) {
    return '...'.concat(value.substring(percentage, value.length))
  } else if (value && value.length > percentage) {
    return value.substring(0, percentage).concat('...')
  }

  return value
}



export const toggleFullscreenV2 = () => {
  const dd:any = document
    if (!dd.fullscreenElement && !dd.mozFullScreenElement &&
        !dd.webkitFullscreenElement && !dd.msFullscreenElement) {
        if (dd.documentElement.requestFullscreen) {
            dd.documentElement.requestFullscreen();
        } else if (dd.documentElement.mozRequestFullScreen) { // Firefox
            dd.documentElement.mozRequestFullScreen();
        } else if (dd.documentElement.webkitRequestFullscreen) { // Chrome, Safari, and Opera
            dd.documentElement.webkitRequestFullscreen();
        } else if (dd.documentElement.msRequestFullscreen) { // IE/Edge
            dd.documentElement.msRequestFullscreen();
        }
    } else {
        if (dd.exitFullscreen) {
            dd.exitFullscreen();
        } else if (dd.mozCancelFullScreen) { // Firefox
            dd.mozCancelFullScreen();
        } else if (dd.webkitExitFullscreen) { // Chrome, Safari, and Opera
            dd.webkitExitFullscreen();
        } else if (dd.msExitFullscreen) { // IE/Edge
            dd.msExitFullscreen();
        }
    }
};

export function getURLParameters(url) {
  const params = {};
  const urlParts = url.split(/[?&]/).slice(1);

  urlParts.forEach(part => {
    const [key, value] = part.split('=');
    if (key && value) {
      params[key] = decodeURIComponent(value.replace(/\+/g, ' '));
    }
  });

  return params;
}
