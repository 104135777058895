export const ORDER_FETCH_ORDER_FOR_CLIENT_ID = 'order/fetchOrderForClientId'

export const NETWORK_STORE_KEY = {
  'order/fetchOrderForClientId': 'order/fetchOrderForClientId',
  '@auth/send-otp': '@auth/send-otp',
  '@auth/verify-otp': '@auth/verify-otp',
  '@auth/verify-token': '@auth/verify-token',
  '@order/fetch-fulfilled-cases': '@order/fetch-fulfilled-cases',
  '@order/fetch-ongoing-cases': '@order/fetch-ongoing-cases',
  '@order/fetch-cases-history': '@order/fetch-cases-history',
  '@er-user/fetch-er-user': '@er-user/fetch-er-user',
  '@clients/get-user-client-info':'@clients/get-user-client-info'
}
