// src/services/axios-interceptor.ts
import axios, { AxiosError } from 'axios'
// import { logger, showErrorToast } from "../utils";
// import toast from "react-hot-toast";

const TIMEOUT = 20000 // Set a request timeout, if necessary

export const BASE_URL = import.meta.env.VITE_BASE_URL
export const ENVIRONMENT = import.meta.env.VITE_APP_ENVIRONMENT

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: TIMEOUT,
  // headers: {
  //   'Content-Type': 'application/json',
  //   Authorization: `Bearer ${import.meta.env.VITE_AUTH}`,
  // },
})

// Function to handle request interceptor
const handleRequestInterceptor = () => {
  axiosInstance.interceptors.request.use(
    (config) => {
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}

// Function to handle response interceptor with global error handling
const handleResponseInterceptor = () => {
  axiosInstance.interceptors.response.use(
    (response) => {
      // Modify response data here if needed
      return response
    },
    (error) => {
      // Handle response error (if any) with handleError function
      return handleError(error)
    }
  )
}

// const suppressErrorPath = [/create-case\/RED_*/];

// Function to handle network errors
const handleError = (error: AxiosError) => {
  if (error.response) {
    // The request was made, but the server responded with a status code outside of the 2xx range
    const err = error.response?.data || error.response
    const statusCode = error.response.status
    const path = error.response.config.url

    if (statusCode === 401) {
      // Handle 401 (Unauthorized) error
      console.log('Unauthorized: Redirect to login page')
      if (!window.location.pathname.includes('auth')) {
        window.location.pathname = '/logout'
      }
      // You can also redirect the user to the login page or perform any other action.
    } else if (statusCode === 403) {
      const message = errorMessageParser(err)
      // showErrorToast(message)
    } else if (statusCode === 404) {
      // TODO: need to check from Login side.
      // const message = errorMessageParser(error.response);
      // const message = "";
      // showErrorToast(message);
    } else if (statusCode === 500) {
      const message = errorMessageParser(error.response)
      // showErrorToast(message);
    } else {
      // Handle other error codes
      // logger("Interceptor", path, "error");
      console.log('Network error:', error.response.data)
    }
  } else if (error.request) {
    // The request was made, but no response was received
    // logger("Interceptor", "Empty Response ", "error");
    console.log('No response received:', error.request)
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error:', error.message)
  }
  // Return a rejected promise to propagate the error further
  return Promise.reject(error)
}

// Call the interceptor functions
handleRequestInterceptor()
handleResponseInterceptor()

interface ErrorObject {
  [key: string]: any
}

function errorMessageParser(error: any): string {
  // Helper function to check if the value is an object
  const isObject = (val: any): val is object =>
    val && typeof val === 'object' && !Array.isArray(val)

  // Helper function to check if the value is an array of strings
  const isStringArray = (val: any): val is string[] =>
    Array.isArray(val) && val.every((element) => typeof element === 'string')

  // Recursive function to search for a message
  function findErrorMessage(err: ErrorObject): string | null {
    if (typeof err === 'string') {
      return err // Base case: found the message as a string
    } else if (isStringArray(err)) {
      // If the error is an array of strings, return the first element
      return err[0]
    } else if (isObject(err)) {
      // Check common properties for error messages
      const possibleMessageKeys = ['data', 'message', 'error', 'errorMessage']
      for (const key of possibleMessageKeys) {
        if (typeof err[key] === 'string') {
          return err[key] // Found a string message
        } else if (isStringArray(err[key])) {
          // If the property is an array of strings, return the first string
          return err[key][0]
        } else if (isObject(err[key])) {
          // If the property is an object, recurse into it
          const nestedMessage = findErrorMessage(err[key])
          if (nestedMessage) {
            return nestedMessage // Found a message in a nested object
          }
        }
      }
    }
    return null // No message found
  }

  // Attempt to find a message recursively
  const message = findErrorMessage(error)

  // Return the message if found; otherwise, stringify the error object
  return message ? message : JSON.stringify(error, null, 2)
}
