import { axiosInstance } from '@/api/axios-interceptor'
import request from '@/api/request'
import { ISendLoginOtp, IVerifyLoginOtp } from '@/utils/types'

const MDM_TOKEN = import.meta.env.VITE_MDM_AUTH_TOKEN
const HOST_URL = import.meta.env.VITE_BASE_URL

const urls = {
    sendOtp: '/auth/email/er/sendOTP',
    verifyOTP: '/auth/email/er/verifyOTP',
    testToken: '/auth/email/er/testToken',
    erUser: '/ers/v1/erusers',
    dataClient: '/data/clients/info',
}

export const sendLoginOtp = (body: ISendLoginOtp) => {
    const response = request('POST', urls.sendOtp, body)
    return response
}

export const verifyLoginOtp = (body: IVerifyLoginOtp) => {
    const response = request('POST', urls.verifyOTP, body)
    return response
}

export const refetchAuthToken = (token: string) => {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
    const response = request('GET', urls.testToken, {})
    return response
}

export const fetchErUserFromId = (id: string) => {
    const url = `${urls.erUser}/${id}`
    const response = request('GET', url, {})
    return response
}

export const fetchDataClientsInfo = async () => {
    const url = `${HOST_URL}${urls.dataClient}`

    const request = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${MDM_TOKEN}`,
        },
    })

    const response = await request.json()

    return response
}
