import { axiosInstance } from './axios-interceptor'
import { AxiosRequestConfig, AxiosResponse } from 'axios'

const request = <T>(
  method: string,
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  return axiosInstance.request<T>({ method, url, data, ...config })
}

export default request
