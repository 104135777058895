import { useSelector } from 'react-redux'
import { RootState } from '@/store/redux/store'
import { useAppDispatch } from '@/store/redux/hooks'
import { verifyAuthToken } from '@/api/thunks/user.thunk'
import { useNavigate } from 'react-router-dom'
import { useToast } from '@/components/ui/use-toast'

const token = localStorage.getItem('token')

export const useUser = () => {
    const toast = useToast()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { status, errors, user, selectedClientId, clientList, clientMap } = useSelector((state: RootState) => state.userStore)

    const validToken = () => {
        if (token) {
            dispatch(verifyAuthToken(token))
        } else {
            // toast.toast({
            //   title: 'Session Expired',
            //   description: 'your session has expired, please login again',
            // })
            navigate('/sign-in')
        }
    }

    // const getErUser = (id: string) => {
    //   if (id) {
    //     dispatch(getErUserThunk(id))
    //   } else {
    //     toast.toast({
    //       title: 'User Not Found',
    //       description: 'User not found, please login again',
    //     })
    //     navigate('/sign-in')
    //   }
    // }

    return {
        validToken,
        status,
        errors,
        user,
        selectedClientId,
        clientList,
        clientMap
    }
}

export default useUser
