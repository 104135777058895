import { redEvent } from '@/lib/red-hooks'

const email = localStorage.getItem('email')

// Key: <PageName/component Name>_<Action>
export const clickOnProfileAvatar = (data?: any) => {
    redEvent.push({
        key: 'PROFILE_AVATAR_CLICK',
        action: { email, ...(data ? data : {}) },
    })
}

export const clickOnViewProfileOption = (data?: any) => {
    redEvent.push({
        key: 'VIEW_PROFILE_OPTION_CLICK',
        action: { email, ...(data ? data : {}) },
    })
}

export const clickOnLogoutOption = (data?: any) => {
    redEvent.push({
        key: 'LOGOUT_OPTION_CLICK',
        action: { email, ...(data ? data : {}) },
    })
}

export const clickOnFullScreenOption = (data?: any) => {
    redEvent.push({
        key: 'FULL_SCREEN_OPTION_CLICK',
        action: { email, ...(data ? data : {}) },
    })
}

export const click_on_completed_tab = (data?: any) => {
  redEvent.push({
      key: 'COMPLETED_TAB_CLICK',
      action: { email, ...(data ? data : {}) },
  })
}

export const click_on_case_card =  (data?: any) => {
  redEvent.push({
      key: 'CASE_CARD_CLICK',
      action: { email, ...(data ? data : {}) },
  })
}

export const click_on_upcoming_tab = (data?: any) => {
  redEvent.push({
      key: 'UPCOMING_TAB_CLICK',
      action: { email, ...(data ? data : {}) },
  })
}


export const page_view = (data?: {name: string, orderId?:string}) => {
  redEvent.push({
      key: 'PAGE_VIEW',
      action: { email, ...(data ? data : {}) },
  })
}


export const case_search = (data?: any) => {
  redEvent.push({
      key: 'CASE_SEARCH',
      action: { email, ...(data ? data : {}) },
  })
}


export const switch_ongoing_case_tabs = (data?: any) => {
  redEvent.push({
      key: 'ONGOING_CASE_SWITCH',
      action: { email, ...(data ? data : {}) },
  })
}


export const case_sidebar_control = (data?: any) => {
  redEvent.push({
      key: 'CASE_SIDEBAR_CONTROL',
      action: { email, ...(data ? data : {}) },
  })
}


export const call_paramedic_button_clicked = (data?: any) => {
  redEvent.push({
      key: 'CALL_PARAMEDIC_BUTTON_CLICKED',
      action: { email, ...(data ? data : {}) },
  })
}
