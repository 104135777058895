import { AppDispatch, RootState } from '@/store/redux/store'
import { debounce } from '@/utils'
import { useMemo } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

const DEBOUNCE_DURATION = 300

export const useAppDispatch = () => {
  const dispatch = useDispatch<AppDispatch>()

  // Create a memoized debounced dispatch function
  // const debouncedDispatch = useMemo(() => {
  //   return debounce(dispatch, DEBOUNCE_DURATION)
  // }, [dispatch])

  const debouncedDispatch = debounce(dispatch, DEBOUNCE_DURATION)

  return debouncedDispatch
}

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
