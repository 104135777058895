import { Events, NetworkConfig } from '@red.health/red-hooks'

export const redEvent = new Events({
    segment: {
        writeKey: import.meta.env.VITE_SEGMENT_WRITE_KEY,
    },
    clevertap: {
        accountId: import.meta.env.VITE_CLEVERTAP_ACCOUNT_ID,
    },
})

export const redNetworkConfig = new NetworkConfig({
    config: {
        baseURL: 'https://staging-red-keeper.command.red',
        timeout: 10000,
    },
    response: {
        error: {
            '401': (error) => {
                console.log('400 error', error)
            },
            '500': (error) => {
                console.log('500 error', error)
            },
        },
    },
})
